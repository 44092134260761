'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { COOKIE_CONSENT } from 'constants/storage';
import Paper from 'components/Paper';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { CookieConsentState } from './constants';
import { getCookieConsent, removeUnnecessaryCookies } from './utils';
import styles from './CookieConsentBanner.module.scss';
import CookiesImage from './cookie.svg';
const CookieConsentBanner = () => {
  const t = useTranslations();
  const [consentGiven, setConsentGiven] = useState<CookieConsentState>(CookieConsentState.Accepted);
  useEffect(() => {
    // We want this to only run once the client loads
    // or else it causes a hydration error
    setConsentGiven(getCookieConsent());
  }, []);
  const handleConsent = (consentState: CookieConsentState) => {
    localStorage.setItem(COOKIE_CONSENT, consentState);
    if (consentState === CookieConsentState.Declined) {
      removeUnnecessaryCookies();
    }
    setConsentGiven(consentState);
  };
  if (consentGiven !== CookieConsentState.Undecided) {
    return null;
  }
  return <Paper className={styles.root} data-sentry-element="Paper" data-sentry-component="CookieConsentBanner" data-sentry-source-file="CookieConsentBanner.tsx">
      <Image src={CookiesImage} alt="Cookie" width={48} height={48} data-sentry-element="Image" data-sentry-source-file="CookieConsentBanner.tsx" />
      <div className={styles.content}>
        <Typography variant="label9" component="p" data-sentry-element="Typography" data-sentry-source-file="CookieConsentBanner.tsx">
          {t('cookieConsentBanner.title')}
        </Typography>
        <Button variant="primary" onClick={() => handleConsent(CookieConsentState.Accepted)} data-sentry-element="Button" data-sentry-source-file="CookieConsentBanner.tsx">
          {t('cookieConsentBanner.acceptAll')}
        </Button>
        <Button variant="outlined" onClick={() => handleConsent(CookieConsentState.Declined)} data-sentry-element="Button" data-sentry-source-file="CookieConsentBanner.tsx">
          {t('cookieConsentBanner.decline')}
        </Button>
      </div>
    </Paper>;
};
export default CookieConsentBanner;