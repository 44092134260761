'use client';

import React, { Suspense, useContext, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import Button from 'components/Button';
import { Link, usePathname } from 'utils/navigation';
import AuthorizationModal from 'features/Modal/Authorization';
import { AuthorizationContext } from 'contexts/AuthorizationContext';
import { ReactComponent as Logo } from 'public/assets/icons/logo.svg';
import Profile from './Profile';
import Search from './Search';
import MobileMenuTrigger from './MobileMenuTrigger';
import Navigation from './Navigation';
import styles from './Header.module.scss';
const blurredPages = ['/topic/[id]', '/city/[id]', '/region/[id]'];
const Header: React.FC = () => {
  const t = useTranslations('Header');
  const pathname = usePathname();
  const {
    isAuthorized,
    openAuthorization
  } = useContext(AuthorizationContext);
  const isBlurred = useMemo(() => blurredPages.includes(pathname), [pathname]);
  const handleHeaderInitialize = (el: HTMLDivElement) => {
    if (el) {
      const observer = new IntersectionObserver(([e]) => {
        const isScrolled = e.intersectionRatio < 1;
        e.target.classList.toggle(styles.isSticky, isScrolled);
        if (isScrolled) {
          e.target.setAttribute('data-is-sticky', 'true');
        } else {
          e.target.removeAttribute('data-is-sticky');
        }
      }, {
        threshold: [1]
      });
      observer.observe(el);
    }
  };
  return <header className={styles.root} ref={handleHeaderInitialize} data-blurred={isBlurred} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <div className={styles.wrapper}>
        <Link href="/" className={styles.logoContainer} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
          <Logo className={styles.logo} data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
        </Link>
        <nav className={styles.navigation}>
          <MobileMenuTrigger className={styles.navigationButton} data-sentry-element="MobileMenuTrigger" data-sentry-source-file="Header.tsx" />
          <Navigation className={styles.menu} data-sentry-element="Navigation" data-sentry-source-file="Header.tsx" />
        </nav>
        <Suspense data-sentry-element="Suspense" data-sentry-source-file="Header.tsx">
          <Search onSubmit={() => {}} classes={{
          root: styles.search,
          input: styles.searchInput
        }} data-sentry-element="Search" data-sentry-source-file="Header.tsx" />
        </Suspense>
        <div className={styles.profile}>
          {!isAuthorized && <Button variant="primary" size="medium" className={styles.signInTelegram} onClick={openAuthorization}>
              {t('profile.signInTelegram')}
            </Button>}
          <AuthorizationModal data-sentry-element="AuthorizationModal" data-sentry-source-file="Header.tsx" />
          <Profile avatarClassName={styles.avatar} data-sentry-element="Profile" data-sentry-source-file="Header.tsx" />
        </div>
      </div>
    </header>;
};
export default Header;