import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { useTheme } from 'next-themes';
import cx from 'classnames';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import UserAvatar from 'components/Avatar/User';
import Paper from 'components/Paper';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { languages } from 'constants/language';
import { Theme } from 'constants/theme';
import useWindowResize from 'hooks/useWindowResize';
import useToggle from 'hooks/useToggle';
import { useRouter, Link, usePathname } from 'utils/navigation';
import { logout } from 'serverActions/user/me';
import { AuthorizationContext } from 'contexts/AuthorizationContext';
import { ReactComponent as ArrowIcon } from 'public/assets/icons/arrow-up.svg';
import { ReactComponent as LightIcon } from 'public/assets/icons/light.svg';
import { ReactComponent as DarkIcon } from 'public/assets/icons/dark.svg';
import { ReactComponent as CloseIcon } from 'public/assets/icons/close.svg';
import { ReactComponent as Logo } from 'public/assets/icons/logo.svg';
import AnonymousUserAvatar from './AnonymousUserAvatar';
import styles from './Profile.module.scss';
interface ProfileProps {
  avatarClassName?: string;
}
const Navigation = ({
  isOpen,
  close
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const t = useTranslations();
  const {
    openAuthorization,
    user,
    logout: unauthorizeUser
  } = useContext(AuthorizationContext);
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const {
    resolvedTheme,
    setTheme
  } = useTheme();
  const [openedSubmenu, setOpenedSubmenu] = useState<string | null>(null);
  useEffect(() => {
    if (!isOpen) {
      setOpenedSubmenu(null);
    }
  }, [isOpen]);
  const triggerSubmenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      submenu = null
    } = e.currentTarget.dataset;
    const listEl = document.querySelector<HTMLDivElement>(`.${styles.list}`);
    if (listEl && submenu === 'language') {
      listEl.style.height = '47.5rem';
    } else if (listEl) {
      listEl.style.height = 'auto';
    }
    setOpenedSubmenu(submenu);
  }, [setOpenedSubmenu]);
  const handleSetThemeMode = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      selectTheme: theme = null
    } = e.currentTarget.dataset;
    if (theme) {
      setTheme(theme);
      close();
    }
  }, [setTheme, close]);
  const handleChangeLanguage = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      selectLanguage: locale = null
    } = e.currentTarget.dataset;
    if (!locale) {
      return;
    }
    router.replace(
    // @ts-ignore
    {
      pathname,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: params as any
    }, {
      locale
    });
  }, [pathname, router, params]);
  const handleOpenAuth = () => {
    close();
    openAuthorization();
  };
  return <>
      <div className={cx(styles.main, {
      [styles.hidden]: !!openedSubmenu
    })}>
        {user && <>
            <div className={styles.user}>
              <UserAvatar name={`${user.first_name} ${user.last_name}`} src={user.profile_photo} alt={`${user.first_name} ${user.last_name}`} className={styles.avatar} />
              <Typography variant="h9" color="primary" className={styles.name}>
                {user.first_name} {user.last_name}
              </Typography>
              {user.username && <Typography variant="h12bk">@{user.username}</Typography>}
            </div>
            <div className={styles.divider} />
          </>}
        {user && <>
            <Button variant="text" className={styles.item} component={Link} href="/my-account" onClick={close}>
              <Typography variant="h9bk" adaptive={false}>
                {t('navigation.myAccount')}
              </Typography>
            </Button>
            <Button variant="text" className={styles.item} component={Link} href="/my-channels" onClick={close}>
              <Typography variant="h9bk" adaptive={false}>
                {t('navigation.myChannels')}
              </Typography>
            </Button>
            <div className={styles.divider} />
          </>}
        <Button variant="text" data-submenu="language" className={styles.item} onClick={triggerSubmenu} data-sentry-element="Button" data-sentry-source-file="Profile.tsx">
          <Typography variant="h9bk" adaptive={false} data-sentry-element="Typography" data-sentry-source-file="Profile.tsx">
            {t('Header.profile.language')}
          </Typography>
          <div className={styles.value}>
            <Typography variant="h9bk" adaptive={false} data-sentry-element="Typography" data-sentry-source-file="Profile.tsx">
              {/* @ts-ignore */}
              {t(`Header.profile.languages.${locale}`)}
            </Typography>
            <ArrowIcon className={styles.arrowIcon} data-sentry-element="ArrowIcon" data-sentry-source-file="Profile.tsx" />
          </div>
        </Button>
        <Button variant="text" data-submenu="theme" className={styles.item} onClick={triggerSubmenu} data-sentry-element="Button" data-sentry-source-file="Profile.tsx">
          <Typography variant="h9bk" adaptive={false} data-sentry-element="Typography" data-sentry-source-file="Profile.tsx">
            {t('Header.profile.theme')}
          </Typography>
          <div className={styles.value}>
            <Typography variant="h9bk" adaptive={false} data-sentry-element="Typography" data-sentry-source-file="Profile.tsx">
              {t('Header.profile.themes.contextual', {
              theme: resolvedTheme
            })}
            </Typography>
            <ArrowIcon className={styles.arrowIcon} data-sentry-element="ArrowIcon" data-sentry-source-file="Profile.tsx" />
          </div>
        </Button>
        <div className={styles.divider} />
        {user ? <Button type="submit" variant="text" className={styles.item} onClick={async () => {
        await logout();
        unauthorizeUser();
        close();
      }}>
            <Typography variant="h9bk" adaptive={false}>
              {t('navigation.signOut')}
            </Typography>
          </Button> : <Button variant="text" className={cx(styles.item, styles.signIn)} onClick={handleOpenAuth}>
            {t('Header.profile.signInTelegram')}
          </Button>}
      </div>

      <div className={cx(styles.submenu, {
      [styles.active]: !!openedSubmenu
    })}>
        {openedSubmenu === 'language' ? <>
            <div className={styles.buttonBack}>
              <Button variant="text" className={styles.buttonWithStartIcon} onClick={triggerSubmenu}>
                <ArrowIcon className={styles.backIcon} />
                {t('Header.profile.language')}
              </Button>
            </div>
            <div className={styles.divider} />
            {languages.map(lang => <Button key={lang} onClick={handleChangeLanguage} variant="text" data-select-language={lang} className={cx(styles.item, {
          [styles.itemActive]: locale === lang
        })}>
                {t(`Header.profile.languages.${lang}`)}
              </Button>)}
          </> : <>
            <div className={styles.buttonBack}>
              <Button variant="text" className={styles.buttonWithStartIcon} onClick={triggerSubmenu}>
                <ArrowIcon className={styles.backIcon} />
                {t('Header.profile.theme')}
              </Button>
            </div>
            <div className={styles.divider} />
            <Button onClick={handleSetThemeMode} data-select-theme={Theme.Light} variant="text" className={cx(styles.item, styles.buttonWithStartIcon, {
          [styles.itemActive]: resolvedTheme === Theme.Light
        })}>
              <LightIcon />
              {t('Header.profile.themes.light')}
            </Button>
            <Button onClick={handleSetThemeMode} data-select-theme={Theme.Dark} variant="text" className={cx(styles.item, styles.buttonWithStartIcon, {
          [styles.itemActive]: resolvedTheme === Theme.Dark
        })}>
              <DarkIcon />
              {t('Header.profile.themes.dark')}
            </Button>
          </>}
      </div>
    </>;
};
const Profile: React.FC<ProfileProps> = ({
  avatarClassName
}) => {
  const t = useTranslations('Header.profile');
  const {
    user,
    openAuthorization
  } = useContext(AuthorizationContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isModalOpen, openModal, closeModal] = useToggle(false);
  const open = Boolean(anchorEl);
  const {
    width
  } = useWindowResize();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (width < 1024) {
      openModal();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleOpenAuth = () => {
    handleClose();
    openAuthorization();
  };
  const handleClose = () => {
    closeModal();
    setAnchorEl(null);
  };
  return <div data-sentry-component="Profile" data-sentry-source-file="Profile.tsx">
      <button className={styles.profile} type="button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick} ref={buttonRef}>
        {user ? <UserAvatar name={`${user.first_name} ${user.last_name}`} src={user.profile_photo} alt="Some user avatar" className={avatarClassName} /> : <AnonymousUserAvatar className={avatarClassName} />}
      </button>
      <Menu disableScrollLock id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
      'aria-labelledby': 'basic-button'
    }} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }} PaperProps={{
      elevation: 0
    }} classes={{
      root: styles.menu,
      paper: styles.paper,
      list: styles.list
    }} data-sentry-element="Menu" data-sentry-source-file="Profile.tsx">
        <Navigation isOpen={open} close={handleClose} data-sentry-element="Navigation" data-sentry-source-file="Profile.tsx" />
      </Menu>
      <Modal disableScrollLock onClose={closeModal} open={isModalOpen} aria-labelledby="profile-menu" aria-describedby="modal-profile" className={styles.modal} slotProps={{
      backdrop: {
        className: styles.backdrop
      }
    }} data-sentry-element="Modal" data-sentry-source-file="Profile.tsx">
        <>
          <Logo className={styles.logo} data-sentry-element="Logo" data-sentry-source-file="Profile.tsx" />
          <div className={cx(styles.profileModal, styles.menu)}>
            <Button variant="light" size="large" rounded onClick={closeModal} className={styles.close} data-sentry-element="Button" data-sentry-source-file="Profile.tsx">
              <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="Profile.tsx" />
            </Button>
            <Paper className={styles.paper} data-sentry-element="Paper" data-sentry-source-file="Profile.tsx">
              <div className={styles.actions}>
                {!user && <Button variant="primary" size="large" className={styles.action} onClick={handleOpenAuth}>
                    {t('signInTelegram')}
                  </Button>}
              </div>
              <div className={styles.relative}>
                <Navigation isOpen={open} close={handleClose} data-sentry-element="Navigation" data-sentry-source-file="Profile.tsx" />
              </div>
            </Paper>
          </div>
        </>
      </Modal>
    </div>;
};
export default Profile;