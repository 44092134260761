'use client';

import React, { HTMLAttributes, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { ThemeProvider } from 'next-themes';
import { StyledEngineProvider } from '@mui/material';
import { SWRConfig, useSWRConfig } from 'swr';
import AuthorizationProvider from 'contexts/AuthorizationContext';
import ConfigurationProvider from 'contexts/ConfigurationContext';
import { SnackbarContextProvider } from 'contexts/SnackbarContext';
import type { TUserResponse } from 'business/user/repository/types.d';
import NextAppDirEmotionCacheProvider from './EmotionCache';
import HightlightTheme from './Highlight';
const Providers: React.FC<HTMLAttributes<never> & {
  isAuthorized: boolean;
  user: TUserResponse['user'] | null;
  countries: (string | null)[];
}> = ({
  isAuthorized,
  user,
  countries,
  children
}) => {
  const pathname = usePathname();
  const {
    mutate
  } = useSWRConfig();
  useEffect(() => {
    const storage = window.sessionStorage;
    if (!storage) return;
    const previousPath = storage.getItem('currentPath');
    if (previousPath && previousPath !== pathname) {
      storage.setItem('previousPath', previousPath);
    }
    storage.setItem('currentPath', pathname);
  }, [pathname]);
  return <NextAppDirEmotionCacheProvider options={{
    key: 'mui',
    prepend: true
  }} data-sentry-element="NextAppDirEmotionCacheProvider" data-sentry-component="Providers" data-sentry-source-file="ComposedProviders.tsx">
      <StyledEngineProvider injectFirst data-sentry-element="StyledEngineProvider" data-sentry-source-file="ComposedProviders.tsx">
        <ThemeProvider themes={['light', 'dark']} enableSystem data-sentry-element="ThemeProvider" data-sentry-source-file="ComposedProviders.tsx">
          <ConfigurationProvider countries={countries} data-sentry-element="ConfigurationProvider" data-sentry-source-file="ComposedProviders.tsx">
            <SnackbarContextProvider data-sentry-element="SnackbarContextProvider" data-sentry-source-file="ComposedProviders.tsx">
              <SWRConfig value={{
              loadingTimeout: 12000,
              onLoadingSlow: key => {
                mutate(key);
              }
            }} data-sentry-element="SWRConfig" data-sentry-source-file="ComposedProviders.tsx">
                <AuthorizationProvider isAuthorized={isAuthorized} user={user} data-sentry-element="AuthorizationProvider" data-sentry-source-file="ComposedProviders.tsx">
                  <HightlightTheme data-sentry-element="HightlightTheme" data-sentry-source-file="ComposedProviders.tsx">{children}</HightlightTheme>
                </AuthorizationProvider>
              </SWRConfig>
            </SnackbarContextProvider>
          </ConfigurationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </NextAppDirEmotionCacheProvider>;
};
export default Providers;