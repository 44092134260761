import React from 'react';
import cx from 'classnames';
import Image, { ImageProps } from 'next/image';
import styles from './UserAvatar.module.scss';
export type UserAvatarProps = Omit<ImageProps, 'width' | 'height'> & {
  name: string;
};
const colors = ['#3498db', '#2ecc71', '#e74c3c', '#e67e22', '#9b59b6'];
const getColot = (name: string) => {
  const words = name.replaceAll(/[^A-Za-zА-Яа-я\s]/g, ' ').replaceAll(/\s+/g, ' ').split(' ');
  let letters = '';
  if (words.length === 1) {
    letters = words[0].slice(0, 2).toUpperCase();
  } else {
    const [firstWord, secondWord] = words;
    letters = `${firstWord.slice(0, 1)}${secondWord.slice(0, 1)}`.toUpperCase();
  }
  const indexSum = letters.split('').map(char => char.charCodeAt(0)).reduce((acc, curr) => acc + curr, 0);
  return {
    color: colors[indexSum % colors.length],
    title: letters
  };
};
const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  name,
  ...props
}) => {
  const meta = name ? getColot(name) : {
    title: name,
    color: '#3e3e3e'
  };
  if (!props.src) {
    return <div className={cx(styles.avatar, styles.placeholder, className)}
    // @ts-ignore
    style={{
      '--avatar-color': meta.color
    }}>
        {meta.title}
      </div>;
  }
  return <Image className={cx(styles.root, className)} width={48} height={48} {...props} data-sentry-element="Image" data-sentry-component="UserAvatar" data-sentry-source-file="UserAvatar.tsx" />;
};
export default UserAvatar;