import React, { useEffect, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { usePathname, useSearchParams } from 'next/navigation';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import { useTranslations } from 'next-intl';
import cx from 'classnames';
import Button from 'components/Button';
import SearchInput from 'components/Input/Search';
import SearchComponent from 'features/Search';
import useToggle from 'hooks/useToggle';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import styles from './Search.module.scss';
export interface SearchProps {
  classes?: {
    root?: string;
    input?: string;
  };
  onSubmit: (values: {
    keyword: string;
  }) => void;
}
const Search: React.FC<SearchProps> = ({
  onSubmit,
  classes
}) => {
  const [isActive, activate, disable] = useToggle(false);
  const t = useTranslations('Header.search');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const input = useRef<HTMLInputElement>();
  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === '/' && e.target === document.body) {
        e.preventDefault();
        window.scrollTo({
          top: 0
        });
        input?.current?.focus();
      }
    });
  }, []);
  useEffect(() => {
    if (isActive) {
      disable();
    }
  }, [pathname, searchParams.toString()]);
  return <div className={cx(styles.root, classes?.root)} data-sentry-component="Search" data-sentry-source-file="Search.tsx">
      <Button variant="secondary" className={styles.button} size="large" rounded onClick={activate} id="search-activator" data-sentry-element="Button" data-sentry-source-file="Search.tsx">
        <SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="Search.tsx" />
      </Button>
      <Form onSubmit={onSubmit} render={({
      handleSubmit
    }) => <form onSubmit={handleSubmit} className={styles.form}>
            <Field name="keyword" autoComplete="off" placeholder={t('placeholder')} component={SearchInput} classes={{
        input: classes?.input
      }} InputProps={{
        onFocus: activate,
        inputRef: input,
        startAdornment: null,
        endAdornment: <InputAdornment position="end">
                    <div className={styles.prompt} />
                  </InputAdornment>
      }} />
          </form>} data-sentry-element="Form" data-sentry-source-file="Search.tsx" />
      <Modal open={isActive} onClose={disable} disableScrollLock data-sentry-element="Modal" data-sentry-source-file="Search.tsx">
        <SearchComponent className={styles.search} close={disable} data-sentry-element="SearchComponent" data-sentry-source-file="Search.tsx" />
      </Modal>
    </div>;
};
export default Search;