import React, { HTMLAttributes, useContext } from 'react';
import cx from 'classnames';
import { useTranslations } from 'next-intl';
import { Link } from 'utils/navigation';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as ArrowDownIcon } from 'public/assets/icons/arrow-down.svg';
import { ReactComponent as PlusIcon } from 'public/assets/icons/plus.svg';
import styles from './Navigation.module.scss';
const Navigation: React.FC<HTMLAttributes<HTMLUListElement>> = ({
  className,
  ...rest
}) => {
  const t = useTranslations('navigation');
  const {
    isUkraineGeo
  } = useContext(ConfigurationContext);
  return <ul className={cx(styles.root, className)} {...rest} data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      <li>
        <div className={cx(styles.item, styles.iconed)}>
          {t('catalog')}
          <ArrowDownIcon className={styles.itemIcon} data-sentry-element="ArrowDownIcon" data-sentry-source-file="Navigation.tsx" />
          <div className={styles.hoveredMenu}>
            <div className={styles.content}>
              <Link href="/channels" data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">{t('channels')}</Link>
              {/* <Link href="/groups">{t('groups')}</Link> */}
              <Link href="/posts" data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">{t('posts')}</Link>
              {isUkraineGeo && <Link href="/cities">{t('cities')}</Link>}
              <div className={styles.divider} />
              <Link href="/channel/add" data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">
                <PlusIcon className={styles.icon} data-sentry-element="PlusIcon" data-sentry-source-file="Navigation.tsx" />
                {t('addChannel')}
              </Link>
            </div>
          </div>
        </div>
      </li>
      <li>
        <Link href="/topics" prefetch={false} data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">
          {t('topics')}
        </Link>
      </li>
    </ul>;
};
export default Navigation;