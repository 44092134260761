import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { ReactComponent as UserIcon } from 'public/assets/icons/user.svg';
import styles from './AnonymousUserAvatar.module.scss';
const AnonymousUserAvatar: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  className
}) => {
  return <div className={cx(styles.root, className)} data-sentry-component="AnonymousUserAvatar" data-sentry-source-file="AnonymousUserAvatar.tsx">
      <UserIcon className={styles.avatar} data-sentry-element="UserIcon" data-sentry-source-file="AnonymousUserAvatar.tsx" />
    </div>;
};
export default AnonymousUserAvatar;