import { deleteCookie } from 'cookies-next';
import {
  COOKIE_CONSENT,
  FILTERS_OPENED_KEY,
  METRICS_ORDER_KEY,
  PINNED_CITIES_KEY,
  PINNED_TOPICS_KEY,
} from 'constants/storage';

import { CookieConsentState } from './constants';

export function getCookieConsent(): CookieConsentState {
  if (!localStorage.getItem(COOKIE_CONSENT)) {
    return CookieConsentState.Undecided;
  }
  const cookieConsent = localStorage.getItem(COOKIE_CONSENT);
  if (
    cookieConsent === CookieConsentState.Accepted ||
    cookieConsent === CookieConsentState.Declined
  ) {
    return cookieConsent;
  }

  return CookieConsentState.Undecided;
}

export const removeUnnecessaryCookies = () => {
  const unnecessaryCookies = [
    'sort',
    PINNED_CITIES_KEY,
    PINNED_TOPICS_KEY,
    METRICS_ORDER_KEY,
    FILTERS_OPENED_KEY,
  ];
  unnecessaryCookies.forEach(cookie => deleteCookie(cookie));
};
