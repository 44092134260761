import { ReactNode, useEffect } from 'react';
import { useTheme } from 'next-themes';
const Highlight = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    resolvedTheme = 'light'
  } = useTheme();
  useEffect(() => {
    const isLightTheme = resolvedTheme === 'light';
    const stylesName = isLightTheme ? 'atom-one-light' : 'tokyo-night-dark';
    const css = document.createElement('link');
    css.id = 'highlightjs-theme';
    css.rel = 'stylesheet';
    css.href = `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/${stylesName}.min.css`;
    document.head.appendChild(css);
    return () => {
      const css = document.getElementById('highlightjs-theme');
      if (css) {
        document.head.removeChild(css);
      }
    };
  }, [resolvedTheme]);
  return children;
};
export default Highlight;