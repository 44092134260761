import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/provider/ComposedProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/CookieConsentBanner/CookieConsentBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/features/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/assets/fonts/SuisseIntl-Light.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/SuisseIntl-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/SuisseIntl-Book.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/SuisseIntl-Medium.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/SuisseIntl-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-suisse\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"SuisseFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"cyrillic\"],\"weight\":\"400\",\"display\":\"swap\",\"variable\":\"--font-ibm-plex-mono\"}],\"variableName\":\"IBMPlexFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/app/[locale]/layout.module.scss");
