import React, { useContext, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { ButtonProps } from '@mui/material/Button';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import useToggle from 'hooks/useToggle';
import Button from 'components/Button';
import Paper from 'components/Paper';
import Typography from 'components/Typography';
import { Link } from 'utils/navigation';
import { ConfigurationContext } from 'contexts/ConfigurationContext';
import { ReactComponent as MenuIcon } from 'public/assets/icons/menu.svg';
import { ReactComponent as PlusIcon } from 'public/assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from 'public/assets/icons/close.svg';
import { ReactComponent as CatalogueIcon } from 'public/assets/icons/catalogue.svg';
import { ReactComponent as StarIcon } from 'public/assets/icons/star.svg';
import { ReactComponent as Logo } from 'public/assets/icons/logo.svg';
import styles from './MobileMenuTrigger.module.scss';
const MobileMenuTrigger: React.FC<ButtonProps> = props => {
  const [isOpen, open, close] = useToggle(false);
  const {
    isUkraineGeo
  } = useContext(ConfigurationContext);
  const t = useTranslations('navigation');
  const pathname = usePathname();
  useEffect(() => {
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return <>
      <Button variant="secondary" size="large" rounded onClick={open} {...props} data-sentry-element="Button" data-sentry-source-file="MobileMenuTrigger.tsx">
        <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="MobileMenuTrigger.tsx" />
      </Button>
      <Modal open={isOpen} onClose={close} aria-labelledby="navigation-menu" aria-describedby="modal-navigation" slotProps={{
      backdrop: {
        className: styles.backdrop
      }
    }} data-sentry-element="Modal" data-sentry-source-file="MobileMenuTrigger.tsx">
        <>
          <Logo className={styles.logo} data-sentry-element="Logo" data-sentry-source-file="MobileMenuTrigger.tsx" />
          <div className={styles.root}>
            <Paper className={styles.paper} data-sentry-element="Paper" data-sentry-source-file="MobileMenuTrigger.tsx">
              <nav className={styles.navigation}>
                <ul>
                  <li className={styles.item}>
                    <input type="checkbox" id="cateogry" className={styles.selector} />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="cateogry" className={styles.label}>
                      <CatalogueIcon className={styles.icon} data-sentry-element="CatalogueIcon" data-sentry-source-file="MobileMenuTrigger.tsx" />
                      <Typography variant="h9bk" data-sentry-element="Typography" data-sentry-source-file="MobileMenuTrigger.tsx">{t('catalog')}</Typography>
                    </label>
                    <ul className={styles.submenu}>
                      <li className={styles.submenuItem}>
                        <Link href="/channels" className={styles.link} data-sentry-element="Link" data-sentry-source-file="MobileMenuTrigger.tsx">
                          <Typography variant="h9bk" data-sentry-element="Typography" data-sentry-source-file="MobileMenuTrigger.tsx">{t('channels')}</Typography>
                        </Link>
                      </li>
                      {/* <li className={styles.submenuItem}> */}
                      {/*   <Link href="/groups" className={styles.link}> */}
                      {/*     <Typography variant="h9bk">{t('groups')}</Typography> */}
                      {/*   </Link> */}
                      {/* </li> */}
                      <li className={styles.submenuItem}>
                        <Link href="/posts" className={styles.link} data-sentry-element="Link" data-sentry-source-file="MobileMenuTrigger.tsx">
                          <Typography variant="h9bk" data-sentry-element="Typography" data-sentry-source-file="MobileMenuTrigger.tsx">{t('posts')}</Typography>
                        </Link>
                      </li>
                      {isUkraineGeo && <li className={styles.submenuItem}>
                          <Link href="/cities" className={styles.link}>
                            <Typography variant="h9bk">{t('cities')}</Typography>
                          </Link>
                        </li>}
                      <li className={styles.submenuItem}>
                        <Link href="/channel/add" className={styles.link} data-sentry-element="Link" data-sentry-source-file="MobileMenuTrigger.tsx">
                          <Typography variant="h9bk" className={styles.iconed} data-sentry-element="Typography" data-sentry-source-file="MobileMenuTrigger.tsx">
                            <PlusIcon className={styles.icon} data-sentry-element="PlusIcon" data-sentry-source-file="MobileMenuTrigger.tsx" />
                            {t('addChannel')}
                          </Typography>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.item}>
                    <Link href="/topics" className={styles.label} data-sentry-element="Link" data-sentry-source-file="MobileMenuTrigger.tsx">
                      <StarIcon className={styles.icon} data-sentry-element="StarIcon" data-sentry-source-file="MobileMenuTrigger.tsx" />
                      <Typography variant="h9bk" data-sentry-element="Typography" data-sentry-source-file="MobileMenuTrigger.tsx">{t('topics')}</Typography>
                    </Link>
                  </li>
                </ul>
              </nav>
            </Paper>
            <Button variant="light" size="large" rounded onClick={close} className={styles.close} data-sentry-element="Button" data-sentry-source-file="MobileMenuTrigger.tsx">
              <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="MobileMenuTrigger.tsx" />
            </Button>
          </div>
        </>
      </Modal>
    </>;
};
export default MobileMenuTrigger;